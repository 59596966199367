<template>
  <div class="v_active_page_tencent_meeting un_sel d-flex align-center justify-center bbox"

  >
    <img src="@/assets/images/active_page/tencent_message/v1/logo.png" alt="" class="logo">
    <div class="wrapper_group d-flex align-center justify-center flex-column">
      <img src="~@/assets/images/active_page/tencent_message/v1/word.png" alt="" class="word">
      <img src="~@/assets/images/active_page/tencent_message/v1/button.png" alt="" class="button cp"
        @click.stop="doEnter()"
      >
    </div>

  </div>
</template>

<script>
import moment from 'moment'
export default {
  mounted(){
    document.documentElement.style.fontSize = '13.333333vw';
    this.w_height = window.innerHeight;

    // this.$notice({desc: '1'})
  },
  beforeDestroy(){
    document.documentElement.style.fontSize = '14px';
  },
  data() {
    return {
      list: [
        {
          time: '2022年06月06日',
          url:	'https://meeting.tencent.com/dw/cUvUMAVHkgNS'
        },
        {
          time: '2022年06月07日',
          url:	'https://meeting.tencent.com/dw/uK0xEN9bXtEV'
        },
        {
          time: '2022年06月08日',
          url:	'https://meeting.tencent.com/dw/xgStYYcRQbXc'
        },
        {
          time: '2022年06月09日',
          url:	'https://meeting.tencent.com/dw/L5dv027r9oC1'
        },
      ],
      test_url: 'https://meeting.tencent.com/dw/cUvUMAVHkgNS',
    }
  },
  methods: {
    doEnter(){
      const { list, test_url } = this
      const now = Date.now()
      const format_now = moment(now).format('YYYY年MM月DD日')
      const end_timestamp = moment('2022-06-10 00:00').format('x')
      if (Number(now) < Number(end_timestamp)) {
        const is_in = list.find(item=>{
          return item.time === format_now
        })
        if (is_in) {
          window.open(is_in.url, "_blank")
        } else {
          console.log(test_url)
          window.open(test_url, "_blank")
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v_active_page_tencent_meeting{
  min-height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  background-color: #0A0972;
  padding-top: 1.3rem;
  .logo{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    width: 4.12rem;
  }
  .wrapper_group{
    background-color: #0A0972;
    width: 100%;
    .word{
      width: 6.68rem;
      margin-bottom: 0.5rem;
    }
    .button{
      width: 2.8rem;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
