var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_active_page_tencent_meeting un_sel d-flex align-center justify-center bbox",
    },
    [
      _c("img", {
        staticClass: "logo",
        attrs: {
          src: require("@/assets/images/active_page/tencent_message/v1/logo.png"),
          alt: "",
        },
      }),
      _c(
        "div",
        {
          staticClass:
            "wrapper_group d-flex align-center justify-center flex-column",
        },
        [
          _c("img", {
            staticClass: "word",
            attrs: {
              src: require("@/assets/images/active_page/tencent_message/v1/word.png"),
              alt: "",
            },
          }),
          _c("img", {
            staticClass: "button cp",
            attrs: {
              src: require("@/assets/images/active_page/tencent_message/v1/button.png"),
              alt: "",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.doEnter()
              },
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }